// RECETTE API BASE URL
// var baseUrl = "https://www.andu-gfa-backend.withvolkeno.com";

// PREPROD API BASE URL
var baseUrl = "https://api.preprod.defaru.sn";

// ONLINE API BASE URL
// var baseUrl = 'https://backend.defaru.sn'

export default baseUrl;
